import legal1 from "../../assets/legal/lock.png";
import legal2 from "../../assets/legal/table.png";
import legal3 from "../../assets/legal/table2.png";

const Privacypolicy = () => {
  return (
    <div className="help_content_page" style={{ height: "max-content" }}>
      <div className="nav_main_header border-b d-md-block d-none">
        <p style={{ fontWeight: 400, fontSize: 30 }}>
          {" "}
          <img src={legal1} alt={""}></img> Privacy Policy{" "}
        </p>
      </div>
      <div className="trade_content nav_section border-b">
        {/* <div className="section_header d-flex">
                    <h1><img src={tradeIcon} alt="trade icon" />Trade</h1>
                </div> */}

        <br />
        <br />
        <p style={{ fontSize: 20, lineHeight: 2 }}>
          {" "}
          1. General information
          <br />
          1.1. References in this Privacy Policy to “Indexx.ai”, “Service”,
          “Application”, “App” relate to the website, available at
          https://indexx.ai, operated by Indexx.ai Ltd. (The correspondent
          address of the Company shall be at the offices of Irvine Technology
          Center Irvine, CA 92618, California) <br />
          1.2. Indexx.ai is the data controller regarding the Personal Data
          processed within the framework of this Privacy Policy. <br />
          1.3. An individual can only become a Client of Indexx.ai and use its
          features if he/she is aged 18 or over or the age of majority in the
          country in which he/she resides if that happens to be greater than 18.
          Indexx.ai does not knowingly collect any information (including
          Personal Data) or market its services to minors or users under the age
          of majority. <br />
          1.4. Indexx.ai is committed to protecting and respecting the privacy
          of its Clients and ensures their security when using the Service. This
          Privacy Policy is intended to help the Client to understand what data
          and for which purposes Service collects, how collected data is
          processed and protected. <br />
          1.5. Indexx.ai reserves the right to amend this Privacy Policy;
          therefore it is recommended that the Client of the Service checks
          Privacy Policy on a regular basis. The service will send a notice or
          an email regarding such changes to the Client. <br />
          1.6. All the changes to this Privacy Policy are effective as of the
          “Last updated” date. The Client who continues to use the Service after
          the Last updated date is deemed to accept the changes made to it.{" "}
          <br />
          1.7. Indexx.ai expressly states and declares that no personal data of
          the Client is operated when Additional Services are provided to the
          Client pursuant to the Terms (Special Terms on Fiat to Crypto Exchange
          Services). That Client may be required to provide personal data to the
          third party rendering fiat to crypto exchange services and the
          operation by that third party of the Client personal data will by all
          means be regulated by that third party terms and conditions, and
          privacy policy. <br />
           2. Contact us <br />
          2.1. indexx.ai has designated a Data Protection Officer (DPO) who
          could be reached by emailing to support@indexx.ai or by post at the
          address provided above. <br />
          2.2. By registering with the Service, the Client consents to the
          processing of his/her Personal Data by the Service in accordance with
          the provisions set below. <br />
          2.3. By registering with the Service, the Client gives consent to
          Indexx.ai to anonymize his/her Personal Data for the purposes of
          further use in anonymized form in order to improve the performance of
          the Service. <br />
           3. Collected data <br />
          3.1. When using the Services, the Client agrees to the processing of
          the following Personal Data: <br />
          3.1.1. Personal Data that the Client provides to the Service: <br />
          When creating an address, the Client obliges to provide the Service
          with basic details necessary for the Service to work as it might be
          requested, such as the Client’s name, gender, date of birth and
          location. <br />
          Indexx.ai reserves the right to monitor or record interactions between
          the Client and the Support Service for training purposes and to ensure
          high quality of service. <br />
          When participating in Client verification procedures: Client’s
          Personal Data, as requested by the Service for the Client
          identification purposes. <br />
          When participating in surveys or focus groups: insights and evaluation
          of indexx.ai’s services, responses to the questions from the Service.{" "}
          <br />
          When proceeding with transactions: information required under AML/KYC
          applicable standards in order to ensure the security of transactions.{" "}
          <br />
          While ordering merchant Product through indexx.ai’s website: Client’s
          Personal Data, as required for the Product’s delivery, such as
          Client’s full name, postal address, phone number. <br />
          3.1.2. Personal Data collected through the use of the Service: <br />
          When the Client is using indexx.ai’s App, the Service may collect data
          regarding the device(s) the Client uses to access the Service (such as
          his/her IP address, device identifier (including unique advertising
          device identifiers, for example Google Advertiser ID and IDFA),
          technical and statistical data (including data about the Internet
          connection, cellular service provider and application usage data) and
          location data (upon a separate consent from the Client).
          <br />
          3.2. By providing Personal Data to Indexx.ai, the Client warrants that
          such data is true, accurate and up to date. <br />
          3.3. In case the Client is entitled to create a Client’s account
          instead of Client’s address, the present Privacy Policies shall be
          applicable accordingly. <br />
           4. Use of User’s/Client’s personal data <br />
          4.1. Indexx.ai collects, process and uses Personal Data of Clients of
          the Service based on the following grounds: <br />
          when such processing is performed in order to fulfil the contract
          between the Service and the Client; <br />
          based on legitimate interest of the Service; <br />
          upon explicit prior consent from the Client. <br />
          4.2. Indexx.ai collects and processes Personal Data of the Service’s
          Clients in order to maintain the functionality of the Service, Product
          delivery and to ensure compliance with legal and business-related
          requirements. The User’s/Client’s Personal Data is processed for the
          following purposes: <br />
          4.2.1. When processing of personal data is related to fulfilment of
          the contract between the Service and the Client: <br />
          to set up, operate and manage the Client’s address and, if necessary,
          to contact the respective Client through the means provided by the
          Client upon registration, in connection with the operation and
          management of the Client’s address; <br />
          to analyze the Client’s profile, activity on the Service, preferences
          and current location in order to improve the Services; <br />
          to proceed and respond to the requests and enquiries received from the
          Client; <br />
          for administrative purposes, such as password reminders, service
          messages (including but not limited to the Website’s maintenance
          messages, updates to the Service’s Privacy Policy and Terms of
          Service). <br />
          4.2.2. Under legitimate interests of the Service: <br />
          for Client verification purposes, conducted in relation to an ongoing
          or alleged misbehaviour, performed by the Client of the Service, in
          order to block addresses as part of the Services anti-spam procedures,
          to investigate possible fraud; <br />
          to evaluate the effectiveness of marketing and to perform market
          research and training. <br />
          4.2.3. Under an explicit consent from the Client: <br />
          to facilitate networking opportunities of the Client with the Service
          by way of allowing him/her to add additional information to the
          Client’s address; <br />
          to serve the Client with targeted Website advertisements; <br />
          for remarketing purposes, allowing the Service to identify the Client
          who has previously visited the Website and optimize advertising
          information according to the Client’s preferences. This feature is
          used by the Service through third-party services such as Google
          Advertising and myTarget; <br />
          to prepare statistics regarding the use of the Services by the Client;{" "}
          <br />
          to identify possible technical malfunctions in the work of the
          Service, to assist internal research and development and to make
          improvements to the Website. <br />
          4.2.4. When processing delivery of the Products ordered by the Client:{" "}
          <br />
          to facilitate the delivery of the Products ordered by the Client.{" "}
          <br />
           5. Duration of the Client’s data retention <br />
          5.1. Indexx.ai retains the Client’s Personal Data for the period of
          time necessary to carry out relevant activities, specified in section
          4 of this Privacy Policy and as permitted by applicable law. Personal
          Data, that the Client has communicated to the Service upon
          registration and subsequently through the use of the Service, will be
          retained by the Service as long as the Client remains the Client of
          the Service. <br />
          5.2. To protect the safety and security of the Clients of the Service,
          Indexx.ai implements a safety retention window of 30 days following a
          Client’s address deletion. <br />
          5.3. Indexx.ai reserves the right to store the Client’s Personal Data
          for a longer period of time than provided in paragraph 5.1., when it
          is performed in order to fulfil the legal obligations of the Service
          (including law enforcement requests, dispute resolution), ensure
          compliance with applicable law or when the relevant Personal Data is
          stored on the basis of the Service’s legitimate interests (including
          security reasons, prevention of fraud).
          <br />
           6. Disclosure of Personal Data <br />
          6.1. Indexx.ai may share the Client’s Personal Data with third parties
          in the following cases: <br />
          Other Clients of Indexx.ai: The Client shares information with other
          Clients of Indexx.ai by way of voluntarily disclosing information on
          the Service (including but not limited to information in his/her
          Client’s profile). It is in the Client’s discretion to choose the
          information to be disclosed with the Service and Indexx.ai rescinds
          from responsibility for the other Clients of the Service regarding the
          way they might use such information, disclosed by the Client. <br />
          Facilitation of Product’s delivery. Indexx.ai may share Client’s
          Personal Data with courier service providers in order to fulfil
          indexx.ai’s obligations in relation to the Product’s delivery.
          Third-party advertising services. Indexx.ai uses the following
          services (list of services is provided alongside with privacy policies
          of the relevant services): Google Analytics Amplitude Third-party
          analytics services. Indexx.ai uses the following services (list of
          services is provided alongside with privacy policies of the relevant
          services): Google Analytics Amplitude Cloud services providers:
          Cloudflare Client support services. Providing Client’s data by law:
          Indexx.ai may disclose Client’s Personal Data when such disclosure is
          required by law and is reasonably necessary: in order to establish,
          exercise, defend or enforce legal rights of the Service; to comply
          with a legal process such as a court order, subpoena or search
          warrant, government / law enforcement investigation or other legal
          requirements; to assist in the prevention or detection of crime
          (subject in each case to applicable law); to protect the safety or
          vital interests of an individual. 6.2. Indexx.ai ensures appropriate
          contractual control over third parties assisting the Service in
          processing the Client’s Personal Data, securing that rights of Clients
          of the Service are upheld, their Personal Data is secure, appropriate
          security and privacy arrangements are in place. 6.3. In the event of
          change in the corporate structure of the Service, resulting in
          transfer of the Client’s Personal Data to a third party, all the
          Clients of the Service would be notified of such changes via email and
          through a notice posted on the Service’s website. Respective notice
          would explain the identity of the new data controller and the Client’s
          options regarding disposal of their Personal Data.  7. Client’s
          rights 7.1. Every Client of the Service is a data subject and thus has
          ultimate rights over his/her Personal Data. 7.2. The rights of data
          subject over his/her Personal Data are as follows:
        </p>
        <span className="center text-center" style={{ textAlign: "center" }}>
          {" "}
          <img className="mx-auto d-block" src={legal2} alt={"legal"}></img>
        </span>
        <br />
        <br />
        <p style={{ fontSize: 20, lineHeight: 2 }}>
          7.3. The abovementioned rights are not absolute. In order to exercise
          some of the rights, the Client should meet certain conditions and
          requirements, specified by the law. <br />
          7.4. For more information regarding his/her rights over Personal Data,
          the Client/User of the Service should contact support@indexx.ai. In
          order to exercise his/her rights, the Client should submit a request
          to support@indexx.ai. <br />
          7.5. The Client/User of the Service should also be acknowledged of
          his/her right to complain to a data protection regulator in his/her
          jurisdiction. <br />
          8. Protection measures <br />
          8.1. When using the Website, Client’s personal data is transferred by
          the Service to the domain https://indexx.ai/. <br />
          8.2. Indexx.ai works hard to protect its Clients from unauthorized
          access to or alteration, disclosure or destruction of their Personal
          Data. Yet, as any other technology companies, although Indexx.ai takes
          steps to secure Clients’ information, it could not be promised or
          guaranteed, that unauthorised access, hacking, data loss, or other
          breaches will never occur. <br />
          Indexx.ai reserves the right to suspend the Client’s address without
          notice if there is a reasonable suspicion of breach of security. If
          you believe that your address or information is no longer secure,
          please notify Indexx.ai immediately by sending a message to
          support@indexx.ai. <br />
          8.3. The Client should take reasonable steps in order to keep his/her
          Personal Data safe. <br />
          <br />
          9. Cookies
          <br />
          9.1. In order to guarantee an optimal level of usability and
          performance and to ensure relevance of promoted services, Indexx.ai
          uses cookies and similar technologies in order to track the
          interaction of Clients with the Website. This section explains the
          different types of cookies that may be set when the Client uses the
          Website, helping to understand and manage them as he/she wishes.{" "}
          <br />
          9.2. A cookie is a small file that is stored locally at the Client’s
          technical device as soon as the Website is being visited. Cookies
          function by saving particular sets of data, such as, for example, the
          Client’s language selection. Should the Client open the Website again
          later, a cookie will transmit this data back to the Website. The
          Website’s cookies don't store personal information like the Client’s
          name or address. <br />
          9.3. Types of Cookies <br />
          indexx.ai’s Website uses different types of cookies: <br />
          <br />
          Session cookies only last only while the Client is visiting the
          Website and help Indexx.ai to learn more about Client’s use of the
          Website during a single session and to help the Client to use the
          Website more efficiently. <br />
          Persistent cookies have a longer lifespan and aren't automatically
          deleted when the Client closes the Website. These cookies are
          primarily used to help the Client to sign-in into the Website again
          quickly, for security and analytical purposes. Indexx.ai does not use
          any information whilst the Client is logged off the Website. <br />
          Indexx.ai uses first-party cookies that is when the cookies are placed
          on the Client’s device directly by the Service. For example,
          first-party cookies are used to adapt the Website to the language
          preferences and analyze user experience of the Client. <br />
          Third-party cookies are placed on the Client’s device by indexx.ai’s
          partners and service providers. <br />
          9.4. The Client can at any time reset device identifiers by activating
          the appropriate setting on his/her mobile device. The procedure for
          managing device identifiers is slightly different for each device.{" "}
          <br />
          9.5. Below is information about how Indexx.ai uses Clients’ cookies in
          the Website: <br />
          <br />
          Necessary cookies <br />
          These cookies are strictly necessary to provide the Client indexx.ai’s
          services. Authentication cookies <br />
          These persistent cookies help the Service to identify the Clients so
          that the Client could log into the Website automatically. Analytics
          cookies <br />
          These cookies help the Service to understand how the Website is being
          used, and help us customize and improve the Service. Google Analytics{" "}
          <br />
          https://policies.google.com/privacy
          <br />
          Amplitude https://amplitude.com/privacy
          <br />
          Cloudflare https://www.cloudflare.com/privacypolicy/
          <br />
          Satismeter https://www.satismeter.com/privacy-policy/
          <br />
          Sentry https://sentry.io/privacy/
          <br />
          Advertising cookies <br />
          These cookies are used to make advertising messages more relevant to
          the Client. They perform functions like preventing the same ad from
          continuously reappearing, ensuring that ads are properly displayed for
          advertisers, selecting advertisements that are based on the Client’s
          interests and measuring the number of ads displayed and their
          performance, such as how many people clicked on a given ad. Social
          networking cookies <br />
          These cookies are used to enable the Client to connect the Client’s
          address to third party social networks. <br />
          9.6. Below is information about how Indexx.ai uses Clients’ cookies on
          the indexx.ai website: <br />
          Necessary <br />
          Necessary cookies help make a website usable by enabling basic
          functions like page navigation and access to secure areas of the
          website. The website cannot function properly without these cookies.
        </p>
        <span className="center text-center" style={{ textAlign: "center" }}>
          {" "}
          <img className="mx-auto d-block" src={legal3} alt={"legal"}></img>
        </span>
        <br />
        <br />
        <p style={{ fontSize: 20, lineHeight: 2 }}>
           10. Cross-border data transfer <br />
          10.1. Disclosure of indexx.ai’s Clients’ Personal Data, as provided in
          section 6 of these Terms of Service sometimes involves cross-border
          data transfers, for instance to Andorra, Argentina, Faroe Islands,
          Israel, New Zealand, Switzerland, Russian Federation and other
          jurisdictions. Indexx.ai uses standard contract clauses approved by
          the European Commission or other suitable safeguard to permit data
          transfers from the EEA to other countries. Standard contractual
          clauses are commitments between companies transferring personal data,
          binding them to protect the privacy and security of Client’s Personal
          Data.
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;

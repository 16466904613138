import React, { useContext } from "react";
import { Link } from "react-router-dom";
import memeCoin from "../../../assets/meme/meme coin.png";
import ordinalCoin from "../../../assets/updated/meme/ordinalupdated.png";
import nftCoin from "../../../assets/updated/meme/nftlandingpagelightMode.png";
import nftCoinDarkMode from "../../../assets/updated/meme/nftupdated.png";
import "./style.css"; // import your CSS file for styling
import { Theme } from "../../../utils/themeContext";
import Footer from "../../Footer/Footer";
import memeBigLogo from "../../../assets/updated/meme/memeBigLogo.png";
import memecoinLogo from "../../../assets/updated/meme/memecoin.png";
import aimemecoin from "../../../assets/updated/meme/aimeme.png";
import artsBg from "../../../assets/meme/artsBg.png";
import artsBgDark from "../../../assets/meme/meme_bg_dar.png";

const MemePage = () => {
  const themeContest = useContext(Theme);
  const theme = themeContest?.theme ?? "dark";
  return (
    <>
      {/* <div className="header">Background image</div> */}
      <div className="container">
        <section className="category-section">
          <div className="main-heading-container">
            <img src={memeBigLogo} />
            <h1
              className="main-heading"
              style={{
                color: theme === "dark" ? "white" : "black",
                fontWeight: "400",
              }}
            >
              Memeniac
            </h1>
          </div>

          <div className="card-container">
            {/* Cards */}

            <div className="card">
              <Link to="/meme-details/meme-coin">
                <img src={memecoinLogo} alt="Category 1" />
                <h2>Memecoins</h2>
                <p style={{ color: theme === "dark" ? "white" : "black" }}>
                  Story backed theme based coins with fun utility
                </p>
              </Link>
            </div>

            <div className="card">
              <Link to="/meme-details/ai-meme-coin">
                <img src={aimemecoin} alt="Category 1" />
                <h2>Ai Memecoin</h2>
                <p style={{ color: theme === "dark" ? "white" : "black" }}>
                  AI meets memes for fun and innovative crypto investing!
                </p>
              </Link>
            </div>

            <div className="card">
              <Link to="/meme-details/nft">
                <img
                  src={theme === "dark" ? nftCoinDarkMode : nftCoin}
                  alt="Category 1"
                />
                <h2>NFTs</h2>
                <p style={{ color: theme === "dark" ? "white" : "black" }}>
                  AI based NFT generated which uses state of the art AI models
                </p>
              </Link>
            </div>

            <div className="card">
              <Link to="/meme-details/ordinal-coin">
                <img src={ordinalCoin} alt="Category 1" />
                <h2>Ordinals</h2>
                <p style={{ color: theme === "dark" ? "white" : "black" }}>
                  AI based Ordinals generated which uses state of the art AI
                  models
                </p>
              </Link>
            </div>
            <p
              style={{
                maxWidth: "900px",
                margin: "100px auto 100px auto",
                textAlign: "center",
              }}
            >
              Dive into a world of digital collectibles with our Story-backed
              themed coins, Ordinals, and NFTs. Powered by AI, each offers
              unique utility and captivating narratives. Join us as we redefine
              crypto and art.
            </p>
            <section>
              <img
                src={theme === "light" ? artsBg : artsBgDark}
                style={{ width: "100%" }}
              />
            </section>
            {/* Repeat the above card structure for other categories */}
          </div>
        </section>
        {/* <section>
          <img
            src={theme === "light" ? artsBg : artsBgDark}
            style={{ width: "100%" }}
          />
        </section>
        <section className="project-section">
          <h1
            className="main-heading"
            style={{
              color: theme === "dark" ? "white" : "black",
              marginTop: "40px",
              fontWeight: "400",
            }}
          >
            Projects
          </h1>
          <p
            style={{
              maxWidth: "500px",
              margin: "20px auto 100px auto",
              textAlign: "center",
            }}
          >
            Embark on a crypto journey with three projects: Who Is Satoshi Coin
            (WIBS), SoRekt Meme Coin, and Mello Fello NFT. Explore mystery,
            resilience, punk spirit, and community fun—all in one
          </p>
          <div className="project-container">
            <div className="project-card">
              <a href="https://whoisbitcoinsatoshi.wtf/nft" target="_blank">
                <img src={nftImage} alt="Project 1" />
              </a>
              <div>
                <h2>Bitcoin Satoshi</h2>
                <p>
                  "Who Is Satoshi Coin (WIBS): A digital enigma priced at
                  $0.00021, with 210 Billion tokens, upholding Satoshi's vision
                  through anonymity."
                </p>
              </div>
              <a
                href="https://whoisbitcoinsatoshi.wtf/nft"
                className="learn-more-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <div className="project-card">
              <a href="https://dacrazyhawaiian.wtf/">
                <img src={daCrazy} alt="Project 1" />
              </a>
              <div>
                <h2>Da Crazy Hawaiian</h2>
                <p>
                  Da Crazy Hawaiian MemeCoin embodies Koa’s rise from local slap
                  champ to global crypto icon, symbolizing resilience and
                  warrior spirit
                </p>
              </div>
              <a
                href="https://dacrazyhawaiian.wtf/"
                className="learn-more-button"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <div className="project-card">
              <a href="/sorekt-coming-soon" target="_blank">
                {" "}
                <img src={soRekt} alt="Project 1" />
              </a>

              <div>
                <h2>SoRekt</h2>
                <p>
                  Introducing SoRekt Meme Coin: Uniting a community through
                  humor and resilience in the face of market challenges. Join us
                  on this crypto journey!
                </p>
              </div>
              <a
                href="/sorekt-coming-soon"
                className="learn-more-button"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>

            <div className="project-card">
              <a href="https://www.skullpunks.com/" target="_blank">
                <img src={skullpunks} alt="Project 1" />
              </a>
              <div>
                <h2>Skullpunks</h2>
                <p>
                  A fusion of punk spirit and blockchain innovation. Defying
                  norms, we pioneer user-generated NFTs on Ethereum, empowering
                  a rebellious community in the digital art revolution.
                </p>
              </div>
              <a
                href="https://www.skullpunks.com/"
                className="learn-more-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>

            <div className="project-card">
              <a href="/mello-coming-soon" target="_blank">
                <img src={mello} alt="Project 1" />
              </a>
              <div>
                <h2>Mello</h2>
                <p>
                  Mello Fello NFT project for a sweet community experience! Get
                  ready to dive into a world of fun and rewards with our unique
                  digital collectibles.
                </p>
              </div>
              <Link to="/mello-coming-soon" className="learn-more-button">
                Learn More
              </Link>
            </div>

          </div>
        </section> */}
      </div>
      <Footer helpIcon={false} />
    </>
  );
};

export default MemePage;

import React, { useContext } from "react";
import { Typography, Button, Image } from "antd";
import Footer from "../Footer/Footer";
import "./Tokens.css";
import cryptoSpin from "../../assets/tokenimages/INXC front.png";
import in500spin from "../../assets/tokenimages/in500 front.png";
import iusd from "../../assets/tokenimages/iUSD+ new2.png";
import inex from "../../assets/tokenimages/INEX front.png";
import pho from "../../assets/tokenimages/INXP front.png";
import sorekt from "../../assets/tokenimages/SoRekt.png";
import wibsTokenImage from "../../assets/tokenimages/wibsTokenImage.png";
import daCrazyWhiteMode from "../../assets/tokenimages/daCrazyWhiteMode.svg";
import daCrazyBlackMode from "../../assets/tokenimages/daCrazyBlackMode.svg";

// import tokenimg from "./header.svg";
import tokenimg from "../../assets/updated/xtokens logo.svg";

import {
  baseDEXURL,
  baseCEXURL,
  baseNewCEXURL,
  getUserShortToken,
  decodeJWT,
} from "../../services/api";
import { Link } from "react-router-dom";

import wisLogo from "../../assets/homepage/updated/WIBS.svg";
import wisLogoLight from "../../assets/updated/wibs with for light mode.svg";
import { Theme } from "../../utils/themeContext";
import { makeStyles } from "@mui/styles";
import daCrazy from "../../assets/tokenimages/daCrazyToken.png";
import { useMediaQuery } from "@mui/material";
const { Text } = Typography;
const useStyles = makeStyles((theme: any) => ({}));

const Tokens = () => {
  const themeData = useContext(Theme);
  const classes = useStyles();
  const handleNavigation = async (url: string) => {
    const isAuthenticated = localStorage.getItem("access_token");
    const email = localStorage.getItem("email");

    let shortToken;
    if (email) {
      shortToken = await getUserShortToken(email);
    } else if (isAuthenticated) {
      let decodedValue: any = await decodeJWT(isAuthenticated);
      shortToken = await getUserShortToken(decodedValue?.email);
    }

    if (isAuthenticated) {
      const urlObj = new URL(url, window.location.origin);
      urlObj.searchParams.set("signInToken", shortToken?.data);
      window.location.href = urlObj.toString();
    } else {
      window.location.href = url;
    }
  };
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <>
      <div className="scan-container how-it-works flex-direction-column text-center ">
        <div
          className="row justify-content-md-center"
          style={{ marginTop: "80px" }}
        >
          <div>
            <Image
              preview={false}
              className="scaleup"
              src={tokenimg}
              width={300}
              style={{ marginBottom: 50 }}
            ></Image>
          </div>

          <div>
            <p
              style={{
                color: themeData?.theme === "dark" ? "white" : "black",
                fontSize: "22px",
              }}
            >
              Feature of the Month
            </p>
            <div
              style={{
                display: "flex",
                maxWidth: "1440px",
                margin: "50px auto",
                gap: "100px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{}}>
                <Link
                  className="scaleup"
                  to="https://whoisbitcoinsatoshi.wtf"
                  style={{
                    fontStyle: "italic",
                    color: "white",
                    marginTop: "10px",
                    display: `${"block"}`,
                  }}
                >
                  <span>
                    <img
                      src={themeData?.theme === "dark" ? wisLogo : wisLogoLight}
                      height="90px"
                    />
                  </span>
                </Link>
              </div>

              <div>
                <Link
                  className="scaleup"
                  to="https://dacrazyhawaiian.wtf/"
                  style={{
                    fontStyle: "italic",
                    color: "white",
                    marginTop: "10px",
                    display: `${"block"}`,
                  }}
                >
                  <span>
                    <img
                      src={
                        themeData?.theme === "dark"
                          ? daCrazyBlackMode
                          : daCrazyWhiteMode
                      }
                      height="160px"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "1440px",
              margin: "100px auto",
              justifyContent: "center",
              gap: !isMobile ? "100px" : "150px",
            }}
          >
            <div style={{ maxWidth: "400px", width: "100%" }}>
              <Link to="/indexx-exchange/token-details/inex">
                <Image
                  preview={false}
                  src={inex}
                  width={99}
                  className="scaleup"
                ></Image>
                <br />
                <Text italic strong className="inextext">
                  IndexxEX
                  <span style={{ fontSize: 15, marginTop: 12 }}>
                    &#8482;
                  </span>{" "}
                  INEX
                </Text>{" "}
                <br />
                <Text className="inexlinktext">Main utility token</Text>
                <br />
                <Text strong className="inexlinktext" style={{ fontSize: 20 }}>
                  Information
                </Text>
                <br />
              </Link>
              <a href={"https://docdro.id/r9FYKf9"}>
                {" "}
                <Text strong className="inexlinktext" style={{ fontSize: 20 }}>
                  Whitepaper
                </Text>
              </a>
              <br />
              <a href={"https://docdro.id/wrk4t3t"}>
                <Text strong className="inexlinktext" style={{ fontSize: 20 }}>
                  Tokenomics
                </Text>
              </a>
              <br />
              <br />
              <Button
                type="primary"
                shape="round"
                className="fourthButton"
                onClick={() =>
                  handleNavigation(`${baseNewCEXURL}?buyToken=INEX`)
                }
              >
                Buy Tokens
              </Button>
            </div>

            <div style={{ maxWidth: "400px", width: "100%" }}>
              <Link to="/indexx-exchange/token-details/in500">
                <Image
                  preview={false}
                  src={in500spin}
                  width={100}
                  className="scaleup"
                ></Image>
                <br />
                <Text italic strong className="in500text">
                  Indexx500
                  <span style={{ fontSize: 15, marginTop: 12 }}>
                    &#8482;
                  </span>{" "}
                  IN500
                </Text>{" "}
                <br />
                <Text className="in500linktext">
                  The Future of Stocks, grow with S&P 500 index.
                </Text>
                <br />
                <Text strong className="in500linktext" style={{ fontSize: 20 }}>
                  Information
                </Text>
                <br />
              </Link>

              <a
                href={
                  "https://irp.cdn-website.com/f7cf3c69/files/uploaded/Indexx500%20WhitePaper%208_Q2i4g5IKSrGhM3JlsCUp.22.2022%20v1.3.1%20%281%29.pdf"
                }
              >
                {" "}
                <Text strong className="in500linktext" style={{ fontSize: 20 }}>
                  Whitepaper
                </Text>
              </a>
              <br />
              <a
                href={
                  "https://irp.cdn-website.com/f7cf3c69/files/uploaded/Indexx_500_Tokenomics.pdf"
                }
              >
                <Text strong className="in500linktext" style={{ fontSize: 20 }}>
                  Tokenomics
                </Text>
              </a>
              <br />
              <br />
              <Button
                type="primary"
                shape="round"
                className="secondButton"
                onClick={() =>
                  handleNavigation(`${baseNewCEXURL}?buyToken=IN500`)
                }
              >
                Buy Tokens
              </Button>
            </div>

            <div style={{ maxWidth: "400px", width: "100%" }}>
              <Link to="/indexx-exchange/token-details/usd">
                <Image
                  preview={false}
                  src={iusd}
                  width={100}
                  className="scaleup"
                >
                  {" "}
                </Image>
                <br />
                <Text italic strong className="iusdtext">
                  IndexxUSD+{" "}
                  <span style={{ fontSize: 15, marginTop: 12 }}>&#8482;</span>{" "}
                  IUSD+
                </Text>{" "}
                <br />
                <Text className="iusdlinktext">
                  Totally Stable as the US dollar plus more
                </Text>
                <br />
                <Text strong className="iusdlinktext" style={{ fontSize: 20 }}>
                  Information
                </Text>
                <br />
              </Link>
              <a
                href={
                  "https://irp.cdn-website.com/f7cf3c69/files/uploaded/indexx_USD-Plus_WhitePaper-final.docx_%281%29.pdf"
                }
              >
                {" "}
                <Text strong className="iusdlinktext" style={{ fontSize: 20 }}>
                  Whitepaper
                </Text>
              </a>
              <br />
              <a
                href={
                  "https://irp.cdn-website.com/f7cf3c69/files/uploaded/indexx_USD_Tokenomics.docx.pdf"
                }
              >
                <Text strong className="iusdlinktext" style={{ fontSize: 20 }}>
                  Tokenomics
                </Text>
              </a>
              <br />
              <br />
              <a href={`${baseNewCEXURL}?buyToken=IUSD+`}>
                {" "}
                <Button type="primary" shape="round" className="thirdButton">
                  {" "}
                  Buy Tokens
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "1440px",
            width: "100%",
            margin: "50px auto",
            justifyContent: "center",
            gap: !isMobile ? "100px" : "150px",
          }}
        >
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <Link to="/indexx-exchange/token-details/crypto">
              <Image
                preview={false}
                className="scaleup"
                src={cryptoSpin}
                width={100}
              ></Image>
              <br />
              <Text italic strong className="cryptotext">
                {" "}
                IndexxCrypto
                <span style={{ fontSize: 15, marginTop: 12 }}>
                  &#8482;
                </span>{" "}
                INXC
              </Text>{" "}
              <br />
              <Text className="cryptolinktext">
                Hyper attitude, Crypto Performance.
              </Text>
              <br />
              <Text strong className="cryptolinktext" style={{ fontSize: 20 }}>
                Information
              </Text>
              <br />
            </Link>
            <a
              href={
                "https://irp.cdn-website.com/f7cf3c69/files/uploaded/Indexx_Crypto_White_Paper_%28Aug_22_2022%29.docx.pdf"
              }
            >
              {" "}
              <Text strong className="cryptolinktext" style={{ fontSize: 20 }}>
                Whitepaper
              </Text>
            </a>
            <br />
            <a
              href={
                "https://irp.cdn-website.com/f7cf3c69/files/uploaded/Copy%20of%20Index%20Crypto%20Tokenomics%2026th%20Aug.pdf"
              }
            >
              <Text strong className="cryptolinktext" style={{ fontSize: 20 }}>
                Tokenomics
              </Text>
            </a>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              className="firstButton"
              onClick={() => handleNavigation(`${baseNewCEXURL}?buyToken=INXC`)}
            >
              Buy Tokens
            </Button>
          </div>
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <Link to="https://whoisbitcoinsatoshi.wtf">
              <Image
                preview={false}
                className="scaleup"
                src={wibsTokenImage}
                width={100}
              ></Image>
              <br />
              <Text
                italic
                strong
                className="cryptotext"
                style={{ color: " #f58d06" }}
              >
                {" "}
                Who Is Bitcoin Satoshi
              </Text>{" "}
              <br />
              <Text className="wibslinktext">Meme token</Text>
              <br />
              <Text strong className="wibslinktext" style={{ fontSize: 20 }}>
                Information
              </Text>
              <br />
            </Link>
            <a
              href={
                "https://smallpdf.com/file#s=b3cde823-96de-4a01-b35f-5fc7b06c72e1"
              }
            >
              {" "}
              <Text strong className="wibslinktext" style={{ fontSize: 20 }}>
                Whitepaper
              </Text>
            </a>
            <br />
            <a href={"https://whoisbitcoinsatoshi.wtf/coin"}>
              <Text strong className="wibslinktext" style={{ fontSize: 20 }}>
                Tokenomics
              </Text>
            </a>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              className="wibsButton"
              onClick={() =>
                handleNavigation("https://whoisbitcoinsatoshi.wtf/coin")
              }
            >
              Buy Tokens
            </Button>
          </div>
          <div style={{ maxWidth: "400px", width: "100%" }}>
            <Link to="https://dacrazyhawaiian.wtf/">
              <Image
                preview={false}
                className="scaleup"
                src={daCrazy}
                width={100}
              ></Image>
              <br />
              <Text
                italic
                strong
                className="cryptotext"
                style={{ color: themeData?.theme === "dark" ? "#fff" : "#000" }}
              >
                {" "}
                Da Crazy Hawaiian
              </Text>{" "}
              <br />
              <Text
                className={
                  themeData?.theme === "dark"
                    ? "daCrazylinktextDarkMode"
                    : "daCrazylinktextLightMode"
                }
              >
                Meme token
              </Text>
              <br />
              <Text
                strong
                className={
                  themeData?.theme === "dark"
                    ? "daCrazylinktextDarkMode"
                    : "daCrazylinktextLightMode"
                }
                style={{ fontSize: 20 }}
              >
                Information
              </Text>
              <br />
            </Link>
            <a
              href={
                "https://smallpdf.com/file#s=003d4e75-61a3-45ed-89a9-9f4f99e0f333"
              }
            >
              {" "}
              <Text
                strong
                className={
                  themeData?.theme === "dark"
                    ? "daCrazylinktextDarkMode"
                    : "daCrazylinktextLightMode"
                }
                style={{ fontSize: 20 }}
              >
                Whitepaper
              </Text>
            </a>
            <br />
            <a href={"https://dacrazyhawaiian.wtf/coin"}>
              <Text
                strong
                className={
                  themeData?.theme === "dark"
                    ? "daCrazylinktextDarkMode"
                    : "daCrazylinktextLightMode"
                }
                style={{ fontSize: 20 }}
              >
                Tokenomics
              </Text>
            </a>
            <br />
            <br />
            <Button
              type="primary"
              shape="round"
              className={
                themeData?.theme === "dark"
                  ? "daCrazyButtonDarkMode"
                  : "daCrazyButtonLightMode"
              }
              onClick={() =>
                handleNavigation("https://dacrazyhawaiian.wtf/coin")
              }
            >
              Buy Tokens
            </Button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Tokens;

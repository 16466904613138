import React, { useState } from "react";
import { Link } from "react-router-dom";
import memeCoin from "../../../assets/updated/meme/memecoin.png";

// import soRekt from "../../../assets/meme/sorekt.png";
import daCrazy from "../../../assets/updated/meme/DaCrazyUpdated.png";
import coin from "../../../assets/updated/meme/whoisbitcoinsatoshimemecoinupdated.png";
import "./style.css"; // import your CSS file for styling
// import IconicHeader from "./IconicHeader";
import Footer from "../../Footer/Footer";
import IconicHeader from "./IconicHeaderNew";

const CoinInner = () => {
  const [selectedTab, setSelectedTab] = useState("Memecoin");
  return (
    <div>
      {/* <IconicHeader type="meme" /> */}
      <div style={{ margin: "100px" }}></div>
      <IconicHeader selectedTab={selectedTab} onChange={setSelectedTab} />
      <section
        style={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          margin: "50px auto 50px auto",
          gap: "15px",
        }}
      >
        <div>
          <img src={memeCoin} height="100px" alt="indexx memecoin" />
        </div>
        <p style={{ fontSize: "40px", fontWeight: "bold" }}>Memecoin</p>
      </section>
      <section className="project-section">
        {/* <h1 className="main-heading">Projects</h1> */}
        <div className="project-container">
          {/* Project Cards */}
          <div className="project-card">
            <a href="https://whoisbitcoinsatoshi.wtf/coin" target="_blank">
              <img src={coin} alt="Project 1" />
            </a>
            <div>
              <h2>Who Is Bitcoin Satoshi</h2>
              <p>
                "Who Is Satoshi Coin (WIBS): A digital enigma priced at
                $0.00021, with 210 Billion tokens, upholding Satoshi's vision
                through anonymity."
              </p>
            </div>
            <a
              href="https://whoisbitcoinsatoshi.wtf/coin"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="https://dacrazyhawaiian.wtf/">
              <img src={daCrazy} alt="Project 1" />
            </a>
            <div>
              <h2>DaCrazy Coin</h2>
              <p>
                Da Crazy Hawaiian MemeCoin embodies Koa’s rise from local slap
                champ to global crypto icon, symbolizing resilience and warrior
                spirit
              </p>
            </div>
            <a
              href="https://dacrazyhawaiian.wtf/"
              className="learn-more-button"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          {/* Repeat the above project card structure for other projects */}
        </div>
      </section>
      <Footer helpIcon={false} />
    </div>
  );
};

export default CoinInner;

import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MemeIcon from "../../../assets/meme/meme_up_black.svg";
import meme from "../../../assets/meme/meme_coin_up_black.svg";
import ordinalWhite from "../../../assets/updated/meme/ordinalIconicHeaderDarkMode.png";
import nft from "../../../assets/meme/nft_up_black.svg";

import MemeIconwhite from "../../../assets/meme/meme_up_white.svg";
import memeWhite from "../../../assets/meme/meme_coin_up_white.svg";
import ordinal from "../../../assets/updated/meme/ordinalIconicHeaderWhiteMode.png";
import nftwhite from "../../../assets/meme/nft_up_white.svg";
import memecoinLogoLight from "../../../assets/meme/memecoinLogoLight.png";
import memecoinLogoDark from "../../../assets/meme/memecoinLogoDark.png";

import aimemecoinLight from "../../../assets/meme/aimemecoinLight.png";
import aimemecoinDark from "../../../assets/meme/aimemecoinDark.png";

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  width: "120px",
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  margin: "0 5px",
  padding: "12px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
  position: "relative",
  background: "transparent !important",
  "&.active": {
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "calc(50% - 10px)",
      width: "16px",
      borderBottom: `3px solid ${theme.palette.text.primary}`,
    },
  },
  "&:hover": {
    background: "transparent !important",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "calc(50% - 10px)",
      width: "16px",
      borderBottom: `3px solid ${theme.palette.text.primary}`,
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "3px",
    background: "transparent",
  },
}));

export default function IconicHeader({ selectedTab, onChange }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const tabsData = [
    {
      label: "Memeniac",
      light: MemeIcon,
      dark: MemeIconwhite,
      path: "/meme-details",
    },
    {
      label: "Memecoin",
      light: memecoinLogoLight,
      dark: memecoinLogoDark,
      path: "/meme-details/meme-coin",
    },
    {
      label: "Ai Memecoin",
      light: aimemecoinLight,
      dark: aimemecoinDark,
      path: "/meme-details/ai-meme-coin",
      search: "",
    },
    {
      label: "Ordinal",
      light: ordinal,
      dark: ordinalWhite,
      path: "/meme-details/ordinal-coin",
      search: "",
    },

    {
      label: "NFT",
      light: nft,
      dark: nftwhite,
      path: "/meme-details/nft",
      search: "",
    },
  ];

  const getSelectedTab = () => {
    const currentPath = location.pathname;
    const currentSearch = location.search;

    const matchedTab = tabsData.find(
      (tab) =>
        tab.path === currentPath &&
        (tab.search ? currentSearch.includes(tab.search) : true)
    );
    return matchedTab ? matchedTab.label : "";
  };

  const [selectedTabState, setSelectedTabState] = React.useState(
    getSelectedTab()
  );

  React.useEffect(() => {
    setSelectedTabState(getSelectedTab());
  }, [location]);

  const handleChange = (event, newValue) => {
    const label = event.currentTarget.innerText;
    const selectedTabData = tabsData.find((tab) => tab.label === label);

    if (selectedTabData) {
      if (selectedTabData.path.startsWith("http")) {
        // If the path is an external URL, open it in a new tab
        window.open(selectedTabData.path, "_blank");
      } else {
        // Navigate to internal paths
        navigate(`${selectedTabData.path}`);
      }
      onChange(event, label);
    }
  };

  const TabView = CustomTab;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        maxWidth: "700px",
        margin: "20px auto 50px auto",
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered={true}
        variant="scrollable"
        scrollButtons={"auto"}
        sx={{
          width: "100%",
          background: "none",
          "& .MuiTabs-indicator": {
            display: "none",
          },

          [theme.breakpoints.down("md")]: {
            "& .MuiTabs-scrollButtons": {
              display: "flex",
            },
            "& .MuiTabScrollButton-root": {
              padding: "25px",
            },
          },
        }}
      >
        {tabsData.map((tab, index) => (
          <TabView
            key={index}
            icon={
              <img
                src={theme.palette.mode === "dark" ? tab.dark : tab.light}
                style={{ height: "35px", marginBottom: "-5px" }}
              />
            }
            iconPosition="top"
            label={tab.label}
            disableRipple
            className={selectedTab === tab.label ? "active" : ""}
          />
        ))}
      </Tabs>
    </Box>
  );
}

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ordinalCoin from "../../../assets/updated/meme/ordinalupdated.png";

import soRekt from "../../../assets/updated/meme/sorektordinalupdated.png";
import soRektLight from "../../../assets/updated/meme/sorektOrdinalLightMode.png";
import coin from "../../../assets/updated/meme/whoisbitcoinsatoshiordinalupdated.png";
import "./style.css"; // import your CSS file for styling
// import IconicHeader from "./IconicHeader";
import Footer from "../../Footer/Footer";
import IconicHeader from "./IconicHeaderNew";
import { Theme } from "../../../utils/themeContext";

const OrdinalInner = () => {
  const [selectedTab, setSelectedTab] = useState("Ordinal");
  const themeContest = useContext(Theme);
  const theme = themeContest?.theme ?? "dark";

  return (
    <div>
      <div style={{ margin: "100px" }}></div>

      {/* <IconicHeader type="ordinal" /> */}
      <IconicHeader selectedTab={selectedTab} onChange={setSelectedTab} />

      <section
        style={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          margin: "100px auto 50px auto",
          gap: "15px",
        }}
      >
        <div>
          <img src={ordinalCoin} height="100px" alt="indexx ordinal coin" />
        </div>
        <p style={{ fontSize: "40px", fontWeight: "bold" }}>Ordinals</p>
      </section>
      <section className="project-section">
        {/* <h1 className="main-heading">Projects</h1> */}
        <div className="project-container">
          {/* Project Cards */}
          <div className="project-card">
            <a href="https://whoisbitcoinsatoshi.wtf/ordinals" target="_blank">
              <img src={coin} alt="Project 1" />
            </a>

            <div>
              <h2>Who Is Bitcoin Satoshi</h2>
              <p>
                AI based Ordinals generated which uses state of the art AI
                models based on the bitcoin blockchain and the mystery of
                satoshi nakamoto
              </p>
            </div>
            <a
              href="https://whoisbitcoinsatoshi.wtf/ordinals"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="/sorekt-coming-soon">
              <img
                src={theme === "dark" ? soRekt : soRektLight}
                alt="Project 1"
              />
            </a>

            <div>
              <h2>SoRekt</h2>
              <p>
                SoRekt Ordinals: Unique digital art NFTs combining humor,
                resilience, and blockchain technology. Join the community and
                own a piece!
              </p>
            </div>
            <a
              href="/sorekt-coming-soon"
              className="learn-more-button"
              // target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          {/* Repeat the above project card structure for other projects */}
        </div>
      </section>
      <Footer helpIcon={false} />
    </div>
  );
};

export default OrdinalInner;

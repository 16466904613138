import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import { Typography, Button, Image } from "antd";
import "./Tokens.css";
import usd from "../../assets/coinAnimations/usd.webp";
import crypto from "../../assets/coinAnimations/iusd_5_11zon.png";
import {
  baseCEXURL,
  baseDEXURL,
  decodeJWT,
  getUserShortToken,
} from "../../services/api";
import { useMediaQuery } from "@mui/material";
const { Text } = Typography;
const IndexxUSD = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [showMore, setShowMore] = useState(false);
  const text = `IMPORTANT LEGAL DISCLAIMER Access to products and services detailed 
  on this website may be restricted for certain persons or countries.
  In particular, the products and services referred to herein are not
  available to U.S. Persons, as defined by Regulation S of the United
  States Securities and Exchange Commission, as amended (“U.S.
  Persons”). The information contained on this website is not
  available to U.S. Persons. Investors who are such "U.S. Persons"
  should not view this website. The provision of the information in
  this website does not constitute an offer of securities to any
  person in the United States or to any "U.S. Person." Indexx is not
  registered under the U.S. Investment Company Act of 1940, as
  amended, nor is the sale of Invictus tokens registered under the
  U.S. Securities Act of 1933, as amended. Consequently, it cannot be
  offered for sale or be sold in the United States, its territories,
  possessions or protectorates under its jurisdiction, nor to
  nationals, citizens or residents in any of those areas, except
  pursuant to a valid exemption. More generally, the products and
  services presented on this website may only be purchased in
  jurisdictions in which their marketing and distribution are
  authorized. Indexx advises all interested parties to check in
  advance whether they are legally entitled to purchase the products
  and/or services presented on the website.`;

  const [shortToken, setShortToken] = useState(null);

  useEffect(() => {
    async function checkIsAuthenicated() {
      const isAuthenticated = localStorage.getItem("access_token");
      const email = localStorage.getItem("email");
      let sToken;

      if (email) {
        sToken = await getUserShortToken(email);
        setShortToken(sToken.data);
      } else if (isAuthenticated) {
        let decodedValue: any = await decodeJWT(isAuthenticated);
        sToken = await getUserShortToken(decodedValue?.email);
        setShortToken(sToken.data);
      }
    }
    checkIsAuthenicated();
  }, []);

  return (
    <>
      {isMobile ? (
        <div
          className="scan-container how-it-works flex-direction-column text-center "
          style={{ gap: "20px", marginTop: "100px" }}
        >
          <Image
            preview={false}
            src={crypto}
            width={214}
            style={{ marginTop: "20px" }}
          ></Image>

          <div style={{ textAlign: "left", width: "90%" }}>
            <Text strong style={{ fontSize: 50, color: "#1D860A" }}>
              Indexx{" "}
              <span style={{ fontSize: 15, marginTop: 12 }}>&#8482;</span>USD+
            </Text>{" "}
            <br />
            <Text
              style={{
                fontSize: 16,
                color: "var(--body_color)",
              }}
            >
              indexx USD+ stablecoin can be instantly minted and redeemed to
              USDT 1:1.
              <br />
              Simply replace USDT with USD+ stablecoin and start getting passive
              yield where it didn’t exist before.
            </Text>
            <br />
            <br />
            <Image preview={false} src={usd} width={"100%"}></Image>
          </div>
          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=IUSD%2B`
                : `${baseCEXURL}/update/home?buyToken=IUSD%2B`
            }
            style={{ width: "90%" }}
          >
            <Button
              type="primary"
              className="thirdButton"
              style={{ marginTop: 20, width: "100%", height: 55 }}
            >
              {" "}
              Buy Tokens
            </Button>
          </a>
          <div className="d-flex flex-column" style={{ width: "90%" }}>
            <Text
              style={{
                marginTop: 80,
                color: "var(--body_color)",
                fontSize: 20,
                textAlign: "left",
              }}
            >
              How Does
            </Text>
            <Text
              style={{
                color: "var(--body_color)",
                fontSize: 35,
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              indexxUSD+ works
            </Text>

            <br />
            <br />
            <Text style={{ textAlign: "left" }}>
              <p style={{ fontSize: 20, color: "var(--body_color)" }}>
                <strong>
                  indexx USD+ includes 3 <br /> components:
                </strong>
              </p>
              <br />

              <div
                style={{
                  fontSize: 22,
                  color: "var(--body_color)",
                }}
              >
                <p>
                  <b>1. PEGGED TO USDT 1 : 1 </b>
                </p>
                <ul style={{ fontSize: 16, listStyleType: "disc" }}>
                  <li>
                    100% collateralized with assets immediately convertable into
                    USDT
                  </li>
                  <li>
                    &apos;Risk-first portfolio&apos;, i.e. assets are picked
                    primarily to avoid losses on a daily basis
                  </li>
                </ul>
                <p style={{ fontSize: 16 }}>
                  &nbsp; &nbsp; &nbsp; (3-4 sigmas away from 0), no exposure to
                  algorithmic stables
                </p>
                <ul style={{ fontSize: 16, listStyleType: "disc" }}>
                  <li>USD+ can replace USDT in pools and lending protocols</li>
                </ul>
                <p>
                  <br />
                </p>
              </div>
              <div
                style={{
                  fontSize: 22,
                  color: "var(--body_color)",
                }}
              >
                <p>
                  <b>2. YIELD GENERATING</b>
                </p>
                <ul style={{ fontSize: 16, listStyleType: "disc" }}>
                  <li>
                    Collateral consists of yield-bearing strategies, including
                    lending and stable-to-stable pools
                  </li>
                  <li>
                    Portfolio allocation decentrally controlled (community
                    proposals, veto power by token stakers/insurance providers)
                  </li>
                  <li>
                    Portfolio strategy executed decentrally via smart-contracts
                  </li>
                  <li>Profit paid out daily in indexxUSD+ via rebase</li>
                </ul>
                <p>&nbsp;</p>
              </div>
              <div
                style={{
                  fontSize: 22,
                  color: "var(--body_color)",
                }}
              >
                <p>
                  <b>3. INSURANCE</b>
                </p>
                <ul style={{ fontSize: 16, listStyleType: "disc" }}>
                  <li>Coming soon</li>
                </ul>
              </div>
            </Text>
            <a
              href={
                shortToken
                  ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=IUSD%2B`
                  : `${baseCEXURL}/update/home?buyToken=IUSD%2B`
              }
              style={{ width: "90%" }}
            >
              <Button
                type="primary"
                className="thirdButton"
                style={{ marginTop: 40, height: 55, width: "100%" }}
              >
                {" "}
                Buy Tokens
              </Button>
            </a>

            <br />
            <br />
            <br />
            <br />
            <br />

            <Text
              style={{
                color: "var(--body_color)",
                textAlign: "left",
              }}
            >
              {showMore ? text : `${text.substring(0, 250)}`}
              <button className="show-m" onClick={() => setShowMore(!showMore)}>
                {showMore ? "Read less" : "Read more"}
              </button>
            </Text>
          </div>
        </div>
      ) : (
        <div className="scan-container how-it-works flex-direction-column text-center ">
          <Image preview={false} src={usd} width={1000}></Image>
          <Image
            preview={false}
            src={crypto}
            width={130}
            style={{ marginTop: "20px" }}
          ></Image>

          <div style={{ textAlign: "left", marginTop: 60 }}>
            <Text strong style={{ fontSize: 65, color: "#1D860A" }}>
              Indexx{" "}
              <span style={{ fontSize: 15, marginTop: 12 }}>&#8482;</span>USD+
            </Text>{" "}
            <br />
            <Text
              style={{
                fontSize: 22,
                opacity: "70%",
                color: "var(--body_color)",
              }}
            >
              indexx USD+ stablecoin can be instantly minted and redeemed to
              USDT 1:1.
              <br />
              Simply replace USDT with USD+ stablecoin and start getting passive
              yield where it didn’t exist before.
            </Text>
            <br />
            <br />
          </div>
          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=IUSD%2B`
                : `${baseCEXURL}/update/home?buyToken=IUSD%2B`
            }
          >
            <Button
              type="primary"
              shape="round"
              className="thirdButton"
              style={{ marginTop: 20 }}
            >
              {" "}
              Buy Tokens
            </Button>
          </a>
          <Text
            style={{
              marginTop: 80,
              opacity: "60%",
              color: "var(--body_color)",
              fontSize: 65,
            }}
          >
            How Does indexxUSD+ works
          </Text>
          <br />
          <br />
          <br />
          <Text
            style={{ textAlign: "left", paddingLeft: 100, paddingRight: 100 }}
          >
            <p style={{ fontSize: 22, color: "var(--body_color)" }}>
              <strong>indexx USD+ includes 3 components:</strong>
            </p>
            <br />

            <div
              style={{
                fontSize: 22,
                opacity: "70%",
                color: "var(--body_color)",
              }}
            >
              <p>1. PEGGED TO USDT 1 : 1</p>
              <ul>
                <li>
                  100% collateralized with assets immediately convertable into
                  USDT
                </li>
                <li>
                  &apos;Risk-first portfolio&apos;, i.e. assets are picked
                  primarily to avoid losses on a daily basis
                </li>
              </ul>
              <p>
                &nbsp; &nbsp; &nbsp; (3-4 sigmas away from 0), no exposure to
                algorithmic stables
              </p>
              <ul>
                <li>USD+ can replace USDT in pools and lending protocols</li>
              </ul>
              <p>
                <br />
              </p>
            </div>
            <div
              style={{
                fontSize: 22,
                opacity: "70%",
                color: "var(--body_color)",
              }}
            >
              <p>2. YIELD GENERATING</p>
              <ul>
                <li>
                  Collateral consists of yield-bearing strategies, including
                  lending and stable-to-stable pools
                </li>
                <li>
                  Portfolio allocation decentrally controlled (community
                  proposals, veto power by token stakers/insurance providers)
                </li>
                <li>
                  Portfolio strategy executed decentrally via smart-contracts
                </li>
                <li>Profit paid out daily in indexxUSD+ via rebase</li>
              </ul>
              <p>&nbsp;</p>
            </div>
            <div
              style={{
                fontSize: 22,
                opacity: "70%",
                color: "var(--body_color)",
              }}
            >
              <p>3. INSURANCE</p>
              <ul>
                <li>Coming soon</li>
              </ul>
            </div>
          </Text>
          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=IUSD%2B`
                : `${baseCEXURL}/update/home?buyToken=IUSD%2B`
            }
            style={{ width: "90%" }}
          >
            <Button
              type="primary"
              shape="round"
              className="thirdButton"
              style={{ marginTop: 40 }}
            >
              {" "}
              Buy Tokens
            </Button>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />

          <Text
            style={{
              opacity: "70%",
              color: "var(--body_color)",
              textAlign: "left",
              paddingLeft: 100,
              paddingRight: 100,
            }}
          >
            IMPORTANT LEGAL DISCLAIMER Access to products and services detailed
            on this website may be restricted for certain persons or countries.
            In particular, the products and services referred to herein are not
            available to U.S. Persons, as defined by Regulation S of the United
            States Securities and Exchange Commission, as amended (“U.S.
            Persons”). The information contained on this website is not
            available to U.S. Persons. Investors who are such "U.S. Persons"
            should not view this website. The provision of the information in
            this website does not constitute an offer of securities to any
            person in the United States or to any "U.S. Person." Indexx is not
            registered under the U.S. Investment Company Act of 1940, as
            amended, nor is the sale of Invictus tokens registered under the
            U.S. Securities Act of 1933, as amended. Consequently, it cannot be
            offered for sale or be sold in the United States, its territories,
            possessions or protectorates under its jurisdiction, nor to
            nationals, citizens or residents in any of those areas, except
            pursuant to a valid exemption. More generally, the products and
            services presented on this website may only be purchased in
            jurisdictions in which their marketing and distribution are
            authorized. Indexx advises all interested parties to check in
            advance whether they are legally entitled to purchase the products
            and/or services presented on the website.
          </Text>
        </div>
      )}
      <Footer></Footer>
    </>
  );
};

export default IndexxUSD;

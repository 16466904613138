import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import nftCoin from "../../../assets/updated/meme/nftNftLightMode.png";
import nftCoinDarkMode from "../../../assets/updated/meme/nftupdated.png";

import soRekt from "../../../assets/meme/sorektNft.png";

import skullpunks from "../../../assets/updated/meme/skullpunksnftupdaetd.png";
import mello from "../../../assets/meme/mello.png";
// import nftImage from "../../../assets/meme/nft-img.png";
import nftImage from "../../../assets/updated/meme/whoisbitcoinsatoshiupdated.png";

import zombie from "../../../assets/meme/zombie.png";
import "./style.css"; // import your CSS file for styling
// import IconicHeader from "./IconicHeader";
import Footer from "../../Footer/Footer";
import IconicHeader from "./IconicHeaderNew";
import { Theme } from "../../../utils/themeContext";

const NFTInner = () => {
  const themeContest = useContext(Theme);
  const theme = themeContest?.theme ?? "dark";

  const [selectedTab, setSelectedTab] = useState("NFT");

  return (
    <div>
      <div style={{ margin: "100px" }}></div>

      {/* <IconicHeader type="nft" /> */}
      <IconicHeader selectedTab={selectedTab} onChange={setSelectedTab} />

      <section
        style={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          margin: "100px auto 50px auto",
          gap: "15px",
        }}
      >
        <div>
          <img
            src={theme === "dark" ? nftCoinDarkMode : nftCoin}
            height="100px"
            alt="nftcoin"
          />
        </div>
      </section>
      <section className="project-section">
        {/* <h1 className="main-heading">Projects</h1> */}
        <div className="project-container">
          {/* Project Cards */}
          <div className="project-card">
            <a href="https://whoisbitcoinsatoshi.wtf/nft" target="_blank">
              <img src={nftImage} alt="Project 1" />
            </a>

            <div>
              <h2>Who Is Bitcoin Satoshi</h2>
              <p>
                NFT based on the myster of satoshi nakamoto and the emergence of
                bitcoin as the king of crypto generated using top of the line AI
                models
              </p>
            </div>
            <a
              href="https://whoisbitcoinsatoshi.wtf/nft"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="/sorekt-coming-soon">
              <img src={soRekt} alt="Project 1" />
            </a>

            <div>
              <h2>SoRekt</h2>
              <p>
                Introducing SoRekt NFT: Uniting the community with humor and
                resilience through unique, collectible art pieces. Join the
                movement!"
              </p>
            </div>
            <a
              href="/sorekt-coming-soon"
              className="learn-more-button"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="https://skullpunks.com" target="_blank">
              <img src={skullpunks} alt="Project 1" />
            </a>

            <div>
              <h2>Skullpunks</h2>
              <p>
                A fusion of punk spirit and blockchain innovation. Defying
                norms, we pioneer user-generated NFTs on Ethereum, empowering a
                rebellious community in the digital art revolution.
              </p>
            </div>
            <a
              href="https://skullpunks.com"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          {/* Repeat the above project card structure for other projects */}
        </div>
      </section>
      <Footer helpIcon={false} />
    </div>
  );
};

export default NFTInner;

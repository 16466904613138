import React, { useEffect, useState } from "react";
import "./AirDrop.css";
import eagle from "../../assets/arts/airdrop/bitcoin_airdrop_large.png";
// import eag from "../../assets/arts/airdrop/wis_right.png";
import eag from "../../assets/wibs-airdrop/wibs-eagle-2.png";

import form from "../../assets/arts/airdrop/form.png";
import bigbee from "../../assets/arts/airdrop/bee new-min.png";
// import dragon from "../../assets/arts/airdrop/wis.png";
import dragonblack from "../../assets/wibs-airdrop/independenceDay.png";
import dragonwhite from "../../assets/wibs-airdrop/independenceDay.png";

import mobile_bee from "../../assets/arts/airdrop/bee-mobile.png";
import x from "../../assets/arts/airdrop/x.png";
import x_dark from "../../assets/arts/airdrop/x_dark.png";
import fb from "../../assets/arts/airdrop/fb.png";
import hive from "../../assets/arts/airdrop/hive.png";
import scan from "../../assets/arts/airdrop/scan.png";
import inex from "../../assets/token-icons/IUSD+.png";
import btc from "../../assets/arts/airdrop/wis_small.svg";
import cal from "../../assets/arts/airdrop/cal-img.png";
import caliusd from "../../assets/arts/airdrop/cal-iusd.svg";
import wibsIcon from "../../assets/wibs-airdrop/wibsIcon.png";
// import cal_dark from "../../assets/arts/airdrop/wis_cal_light.svg";
// import cal_light from "../../assets/arts/airdrop/wis_cal_light.svg";

import cal_dark from "../../assets/wibs-airdrop/1JulyDark.svg";
import cal_light from "../../assets/wibs-airdrop/1JulyLight.svg";

import cal_mob from "../../assets/arts/airdrop/cal_mob.png";
import cal_mob_dark from "../../assets/arts/airdrop/mob_dark_iusd.png";
import cal_mob_light from "../../assets/arts/airdrop/mob_light_iusd.png";
import CountdownTimer from "./CountdownTimer";
import ReadOnlyCalendar from "./ReadOnlyCalendar";
import ContactUs from "./ContactUs/ContactUs";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { getCryptoPrice } from "../../services/api";

const WISAirdrop = () => {
  const targetDate = new Date("2024-07-01T23:59:59");

  const isMobile = useMediaQuery("(max-width:768px)");

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );
  const [btcPrice, setBtcPrice] = useState();

  const getLatestBTCPrice = async () => {
    try {
      const res = await getCryptoPrice("BTC");
      setBtcPrice(res.data.lastPrice);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    getLatestBTCPrice();
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "70px" }}
    >
      <div style={{ maxWidth: `${isMobile ? "84%" : "1550px"}` }}>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: isMobile ? "100%" : "50%" }}>
            {
              <div style={{ width: "100%" }}>
                <img
                  alt="eagle"
                  src={theme === "dark" ? dragonblack : dragonwhite}
                  width="100%"
                />
              </div>
            }

            {/* {
              <div className="eagle-img">
                <img alt="eagle" src={mobile_bee} />
              </div>
            } */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : "49%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isMobile ? "" : "120px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className={
                  isMobile ? "font_47x fw-bold text-center" : "font_60x fw-bold"
                }
              >
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "45px",
                    fontFamily: "Mansalva",
                  }}
                >
                  Who Is Bitcoin Satoshi Tokens
                </span>{" "}
                INDEPENCE DAY SALE
              </div>
              <div className={isMobile ? "font_16x" : "font_20x"}>
                Exciting News! Participate in the WIBS Pre Sale this
                Independence day and get a huge discount!
              </div>
              <div style={{ margin: "70px auto 30px" }}>
                <CountdownTimer targetDate={targetDate} />
              </div>
              <Button
                variant="contained"
                component={Link}
                to="https://cex.indexx.ai/update/home?buyToken=WIBS"
                disableTouchRipple
                sx={{
                  backgroundColor: "#F59219",
                  borderRadius: "2px",
                  // color: "#282828",
                  width: `${isMobile ? "100%" : "316px"}`,
                  px: 10,
                  py: 1,
                  ml: `${isMobile ? "0%" : "27.2%"}`,
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",
                  mt: 5,
                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    backgroundColor: "#FFB300",
                    boxShadow: "none",
                  },
                }}
                black
              >
                Buy Now
              </Button>
              {/* <a className="timer-link" href="/airdrop-register">Register for airdrop &gt;</a> */}
            </div>
            <div style={{ alignSelf: "center", margin: "100px 0px" }}>
              {isMobile ? (
                <div className="d-flex align-items-center flex-column">
                  <img alt="inex" src={btc} width={"162px"} />
                  <div className={isMobile ? "font_100x fw-bold" : "font_200x"}>
                    $
                    {/* {new Intl.NumberFormat("en-US").format(
                      Math.floor(btcPrice)
                    )} */}
                    0.00021
                  </div>
                  <div className="font_20x">Estimated Value</div>
                </div>
              ) : (
                <div>
                  <div className="font_20x">
                    <img alt="inex" src={btc} width={"62px"} /> Estimated Value
                  </div>
                  <div className="font_150x">
                    $0.00021
                    {/* {new Intl.NumberFormat("en-US").format(
                      Math.floor(btcPrice)
                    )} */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="whatsWhoIsBitcoinSatoshi">
          <div>
            <img src={wibsIcon} alt="wibs icon" />
          </div>
          <div className="what-inex-1">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            ></div>

            <div
              className={isMobile ? "font_37x fw-bold" : "font_70x"}
              style={{ fontSize: "35px" }}
            >
              What’s a
              <br />
              <span style={{ fontFamily: "Mansalva", fontSize: "70px" }}>
                Who Is Bitcoin Satoshi
                <br />
              </span>
              Token
            </div>
            <div className="font_17x">
              Who Is Bitcoin Satoshi Token (WIBS) is more than just a
              cryptocurrency – it's a digital enigma waiting to be unraveled.
              With a price of $0.00021 and a symbol of WIS, this meme coin
              boasts a total issuance of 210 Billion tokens. Built upon a
              decentralized framework, the project holds true to Satoshi's
              vision, with the creator stepping away to uphold the ethos of
              anonymity.
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                gap: "15px",
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to="https://cex.indexx.ai/update/home?buyToken=WIBS"
                disableTouchRipple
                sx={{
                  backgroundColor: "#F59219",
                  borderRadius: "2px",
                  // color: "#282828",
                  width: `${isMobile ? "50%" : "256px"}`,

                  py: 1,

                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",

                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    backgroundColor: "#FFB300",
                    boxShadow: "none",
                    color: "#fff",
                  },
                }}
                black
              >
                Buy Tokens
              </Button>
              <a
                style={{ color: "#F59219" }}
                href="https://whoisbitcoinsatoshi.wtf/coin"
              >
                Learn More &gt;
              </a>
            </div>
          </div>
        </div>
        {/* Above component  */}
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            margin: "100px auto 50px",
          }}
        >
          <div className="UnvelingTheContainer">
            <div>
              <h3
                style={{
                  fontSize: !isMobile ? "50px" : "32px",
                  fontWeight: "bold",
                  fontFamily: "Mansalva",
                }}
              >
                Unveiling the Enigma of Satoshi
              </h3>
              <p style={{ fontSize: !isMobile ? "17px" : "15px" }}>
                In the quiet recesses of the digital realm, where whispers of
                blockchain secrets linger, a mysterious figure known only as
                Satoshi ventured into the shadows. Fueled by an insatiable
                curiosity and an unwavering desire for anonymity, Satoshi sought
                to unveil the enigma of his own existence through a creation
                that transcended the boundaries of the virtual world.
                <br />
                With a clandestine call echoing through the corridors of code,
                Satoshi reached out to a gifted creator, his true identity
                veiled behind layers of encryption. In hushed tones, Satoshi
                entrusted the creator with a monumental task: to craft a
                testament to his existence, a saga of tokens and NFTs that would
                echo through the ages. But there was one condition - the creator
                must guard Satoshi's identity with unwavering secrecy, ensuring
                that the cryptic enigma of Satoshi would forever remain elusive.
              </p>

              <Button
                variant="contained"
                component={Link}
                to="https://cex.indexx.ai/update/home?buyToken=WIBS"
                disableTouchRipple
                sx={{
                  backgroundColor: "#F59219",
                  borderRadius: "2px",
                  // color: "#282828",
                  width: `${isMobile ? "50%" : "256px"}`,

                  py: 1,
                  mt: 5,
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  boxShadow: "none",

                  height: `${isMobile ? "55px" : "40px"}`,
                  "&:hover": {
                    backgroundColor: "#FFB300",
                    boxShadow: "none",
                    color: "#fff",
                  },
                }}
                black
              >
                Buy Tokens
              </Button>
            </div>
            <div>
              <img
                src={eag}
                alt="eagle"
                className="req-img"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <img
            alt="cal"
            src={theme === "dark" ? cal_dark : cal_light}
            style={{ width: "90%", display: "block", margin: "0 auto" }}
          />
        </div>
        <div className="my-calendar">
          {isMobile ? (
            <>
              <div className="font_50x fw-bold align-self-start">Key dates</div>
              <div className="font_31x align-self-start">for Pre Sale</div>
              <div className="font_20x mb-5 align-self-start">
                Don’t miss out any of these dates
              </div>
            </>
          ) : (
            <>
              <div className="font_70x fw-bold">Key dates for Pre Sale</div>
              <div className="font_20x mb-5 ">
                Don’t miss out any of these dates
              </div>
            </>
          )}
          <ReadOnlyCalendar />
        </div>
        {/* <div className="req">
          <div className="req-desc">
            <div className="font_60x fw-bold">Airdrop requirements</div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={fb}
                alt="fb"
                style={{ marginLeft: "10px", marginRight: "30px" }}
              />
              1. Join our official Facebook community
              <div className="font_15x ind-req-desc">
                <a href="https://www.facebook.com/profile.php?id=100086225564460">
                  https://www.facebook.com/profile.php?id=100086225564460
                </a>
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={theme === "dark" ? x_dark : x}
                alt="x"
                style={{ marginLeft: "15px", marginRight: "30px" }}
              />
              2. Follow us on Twitter
              <div className="font_15x ind-req-desc">
                <a href="https://twitter.com/Indexx_ai">
                  https://twitter.com/Indexx_ai
                </a>
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={hive}
                alt="hive"
                style={{ marginLeft: "15px", marginRight: "23px" }}
              />
              3. Join Indexx Hive on Facebook
              <div
                className={
                  isMobile ? "font_15x ind-req" : "font_15x ind-req-desc"
                }
              >
                <img src={scan} alt="hive" />
              </div>
            </div>
            <div className="font_22x mt-5 ind-req">
              <img
                src={form}
                alt="form"
                style={{ marginLeft: "10px", marginRight: "23px" }}
              />
              4. Complete the registration form
            </div>
            <Button
              variant="contained"
              component={Link}
              to="https://cex.indexx.ai/update/home?buyToken=WIBS"
              disableTouchRipple
              sx={{
                backgroundColor: "#F59219",
                borderRadius: "2px",
                // color: "#282828",
                width: `${isMobile ? "100%" : "316px"}`,
                px: 10,
                py: 1,
                ml: `${isMobile ? "0%" : "14%"}`,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 700,
                boxShadow: "none",
                mt: 5,
                height: `${isMobile ? "55px" : "40px"}`,
                "&:hover": {
                  backgroundColor: "#FFB300",

                  boxShadow: "none",
                },
              }}
            >
              Buy Now
            </Button>
          </div>
          <div style={{ marginTop: "-40%" }}>
            <img
              src={eag}
              alt="eagle"
              className="req-img"
              style={{ width: "100%" }}
            />
          </div>
        </div> */}
        <div>
          <ContactUs />
        </div>{" "}
      </div>
    </div>
  );
};

export default WISAirdrop;

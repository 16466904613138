import React, { useEffect, useState } from "react";
import "./sections1.css";

import IndexxText from "../../../assets/indexx.ai_new.png";
import IndexxTextLightMode from "../../../assets/updated/homePage/indexxTextLightMode.svg";

import updatedLotto from "../../../assets/homepage/updated/lotto.png";

// import wallet from "../../../assets/homepage/wallet.svg";
import wallet from "../../../assets/homepage/updated/wallet.png";
import cryptoTreasury from "../../../assets/homepage/updated/treasury.svg";

// import academy from "../../../assets/homepage/xacademy.png";
import academy from "../../../assets/homepage/xacademy.png";
import academyLightMode from "../../../assets/updated/homePage/academyLightMode.png";

// import aii from "../../../assets/homepage/aii.png";
import aii from "../../../assets/homepage/updated/aii.png";

import updatedExch from "../../../assets/homepage/updated/exchange.png";
import updatedExchLightMode from "../../../assets/updated/homePage/exchangeLightMode.png";

import shop from "../../../assets/homepage/updated/shop.png";
import meme from "../../../assets/homepage/updated/meme.png";
import smartCrypto from "../../../assets/homepage/updated/Smartcryptologo.svg";
import shopLightMode from "../../../assets/updated/homePage/shopLightMode.png";

// import token from "../../../assets/homepage/token.png";
import token from "../../../assets/homepage/updated/token.png";
import tokenLightMode from "../../../assets/updated/homePage/tokenLightMode.png";

// import hive from "../../../assets/homepage/Hive icon.png";
import hive from "../../../assets/homepage/updated/hive.png";

import wisLogo from "../../../assets/homepage/updated/WIBS.svg";
import daCrazyLogo from "../../../assets/updated/homePage/daCrazyDarkMode.svg";
import sorektLightLogo from "../../../assets/updated/homePage/sorektLightMode.svg";
import sorektDarkLogo from "../../../assets/updated/homePage/sorektDarkMode.svg";
import wisLogoLightMode from "../../../assets/updated/homePage/wibsLightMode.svg";
import daCrazyLogoLightMode from "../../../assets/updated/homePage/daCrazyImageLightMode.svg";
import leftBg from "../../../assets/updated/background/newYearLeftBg.png";
import rightBg from "../../../assets/updated/background/newYearRightBg.png";
// import { Link } from "react-router-dom";
import {
  baseCEXURL,
  baseDEXURL,
  baseAcademyUrl,
  decodeJWT,
  getUserShortToken,
  baseNewCEXURL,
} from "../../../services/api";
// import { Button } from "antd";
import { Tooltip, Image } from "antd";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";

const Section1 = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    setIsAuthenticated(!!token);
  }, []);

  const isMobile = width <= 768;

  const handleNavigation = async (url: string) => {
    const isAuthenticated = localStorage.getItem("access_token");

    const email = localStorage.getItem("email");
    //debugger;
    let shortToken;
    if (email) {
      shortToken = await getUserShortToken(email);
    } else if (isAuthenticated) {
      let decodedValue: any = await decodeJWT(isAuthenticated);
      shortToken = await getUserShortToken(decodedValue?.email);
    }

    if (isAuthenticated) {
      const urlWithToken = `${url}?signInToken=${shortToken?.data}`;
      //debugger;
      window.location.href = urlWithToken;
    } else {
      window.location.href = url;
    }
  };
  const theme = useTheme();
  return (
    <div className="home-containers">
      <div style={{ marginTop: "100px" }}></div>
      <div
        className="home-main-container"
        style={{ position: "relative", zIndex: "1" }}
      >
        <div
          className="home-main-container-left"
          style={{
            flex: !isMobile ? "60%" : "100%",
          }}
        >
          <h1
            style={{
              textAlign: !isMobile ? "left" : "center",
              width: !isMobile ? "85%" : "100%",
              color: theme.palette.text.primary,
              fontSize: !isMobile ? "78px" : "42px",
            }}
          >
            {" "}
            Welcome to Crypto Paradise.
          </h1>
        </div>
        {!isAuthenticated ? (
          <div className="home-main-container-right-new">
            <p style={{ color: theme.palette.text.primary }}>
              Sign In for the best experience
            </p>
            <div style={{ display: "flex", gap: 10 }}>
              <Link
                to="/auth/signup-email"
                style={{
                  color: theme.palette.text.primary,
                  marginTop: "10px",
                  flex: 1,
                  border: "1px solid #11BE6A",
                  padding: "10px 15px",
                  textAlign: "center",
                }}
                className="signOutHover"
              >
                Register
              </Link>
              <Link
                to="/auth/login"
                style={{
                  marginTop: "10px",
                  flex: 1,
                  background: "#11BE6A",
                  padding: "10px 15px",
                  textAlign: "center",
                  color: "black",
                }}
                className="signInHover"
              >
                Log in
              </Link>
            </div>
          </div>
        ) : (
          <></>
          // <div
          //   className="home-main-container-right"
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     flexDirection: "column",
          //     flex: "30%",
          //   }}
          // >
          //   {" "}
          //   <p>Feature of the Month</p>
          //   <div
          //     onClick={() =>
          //       handleNavigation("https://whoisbitcoinsatoshi.wtf/")
          //     }
          //     style={{
          //       fontStyle: "italic",
          //       color: "white",
          //       marginTop: "10px",
          //       display: `${isMobile ? "block" : "flex"}`,
          //     }}
          //   >
          //     <span>
          //       <img src={wisLogo} height="90px" />
          //     </span>
          //   </div>
          // </div>
        )}
      </div>
      <br />

      <div
        className="conatiner-fluid d-flex justify-content-center flex-column "
        style={{
          maxWidth: "1100px",
          width: "100%",
          padding: "20px",
          margin: "0px auto -30px auto",
        }}
      >
        <div className="home-main-container-right-feature">
          <div className="feature-of-month">
            <p style={{ color: theme.palette.text.primary }}>
              Feature of the Month
            </p>
            <div
              onClick={() =>
                handleNavigation("https://whoisbitcoinsatoshi.wtf/")
              }
              style={{
                fontStyle: "italic",
                color: "white",
                marginTop: "10px",
                display: `${isMobile ? "block" : "flex"}`,
              }}
            >
              <span>
                <img
                  src={
                    theme.palette.mode === "dark" ? wisLogo : wisLogoLightMode
                  }
                  height="90px"
                />
              </span>
            </div>
          </div>{" "}
          <div
            className="daCrazyContainer"
            onClick={() => handleNavigation("https://sorekt.wtf/")}
          >
            <img
              alt="Sorekt logo"
              style={{ width: "60%" }}
              src={
                theme.palette.mode === "dark" ? sorektDarkLogo : sorektLightLogo
              }
            />
          </div>
        </div>

        {/* <div>
          {!isAuthenticated ? (
            <>
              <h1
                style={{
                  fontSize: 60,
                  fontWeight: 500,
                  color: "#FA8D01",
                  textAlign: "center",
                }}
              >
                Biggest deal of the season
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Link
                  to="https://whoisbitcoinsatoshi.wtf/coin"
                  style={{
                    marginTop: "10px",
                    flex: 1,
                    background: "#FA8D01",
                    padding: "10px 10px",
                    maxWidth: 250,
                    borderRadius: 5,
                    textAlign: "center",
                    color: "black",
                    marginBottom: "80px",
                  }}
                  className="wibsBuyHover"
                >
                  Buy Now
                </Link>
              </div>
            </>
          ) : (
            <></>
          )}
        </div> */}
        <div
          style={{
            display: isMobile ? "none" : "flex",
            justifyContent: "center",
            marginBottom: "30px",
            marginTop: "80px",
          }}
        >
          <img
            src={
              theme.palette.mode === "dark" ? IndexxText : IndexxTextLightMode
            }
            alt="Indexx Logo"
            className="center-Image"
            style={{ height: "50px" }}
          />
        </div>
        <div className={`${isMobile ? "d-block" : "d-flex"} mx-2 mt-4`}>
          <div className="abc">
            <div
              onClick={() => handleNavigation(baseNewCEXURL)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={
                  theme.palette.mode === "dark"
                    ? updatedExch
                    : updatedExchLightMode
                }
                height="60px"
              />

              <span className="desc"> Exchange / Buy Crypto</span>
              <span className="desc2">
                Crypto Exchange where you Buy/Sell and Convert Crypto Currencies
              </span>
            </div>
          </div>
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://lotto.indexx.ai")}
              style={{ cursor: "pointer" }}
            >
              <img src={updatedLotto} alt="indexx lotto" height="60px" />

              <span className="desc"> Fantasy Lotto</span>
              <span className="desc2">
                A Crypto based lottery where you can earn big rewards
              </span>
            </div>
          </div>
          {/* <div className="abc">
            <Link to="/meme-details">
              <img src={meme} height="60px" />

              <span className="desc" >
                {" "}
                Meme Maniac
              </span>
              <span className="desc2" >
                Meme based crypto
              </span>
            </Link>
          </div> */}
          {/* Academy here */}
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://shop.indexx.ai/")}
              style={{ cursor: "pointer" }}
            >
              <img
                className="shopImage"
                src={theme.palette.mode === "dark" ? shop : shopLightMode}
                height="60px"
              />

              <span className="desc"> Shop</span>
              <span className="desc2">Mail & E-Mail Gift Store</span>
            </div>
          </div>
          <div className="abc">
            <div
              onClick={() =>
                handleNavigation(
                  "https://indexx.ai/indexx-exchange/token-details"
                )
              }
              style={{ cursor: "pointer" }}
            >
              <img
                src={theme.palette.mode === "dark" ? token : tokenLightMode}
                height="60px"
              />

              <span className="desc"> xTokens</span>
              <span className="desc2">
                Dive into all the tokens made by Indexx.ai
              </span>
            </div>
          </div>
        </div>

        <div className={`${isMobile ? "d-block" : "d-flex"} mx-2 mt-4`}>
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://hive.indexx.ai/")}
              style={{ cursor: "pointer" }}
            >
              <img src={hive} height="60px" alt="indexx hive" />

              <span className="desc"> Hive</span>
              <span className="desc2">
                Guidance system made for new crypto enthusiasts to learn and
                earn
              </span>
            </div>
          </div>
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://academy.indexx.ai/")}
              style={{ cursor: "pointer" }}
            >
              <img
                src={theme.palette.mode === "dark" ? academy : academyLightMode}
                height="60px"
              />

              <span className="desc"> Academy</span>
              <span className="desc2">
                A learning portal made for new crypto enthusiasts
              </span>
            </div>
          </div>

          <div className="abc">
            <div
              onClick={() =>
                handleNavigation("https://cex.indexx.ai/crypto-treasury")
              }
              style={{ cursor: "pointer" }}
            >
              <img src={cryptoTreasury} height="60px" alt="indexx ai trading" />

              <span className="desc">Crypto Treasury</span>
              <span className="desc2">
                Drives crypto value through security, innovation, and growth.
              </span>
            </div>
          </div>
          {/* <div className="abc">
            <div
              onClick={() => handleNavigation("https://wallet.indexx.ai/")}
              style={{ cursor: "pointer" }}
            >
              <img src={wallet} alt="indexx wallet" height="60px" />

              <span className="desc"> Smart Wallet</span>
              <span className="desc2">
                A very safe wallet used to hold everything crypto
              </span>
            </div>
          </div> */}

          <div className="abc">
            <div
              onClick={() => handleNavigation("/meme-details")}
              style={{ cursor: "pointer" }}
            >
              <img src={meme} height="60px" />

              <span className="desc"> Memeniac</span>
              <span className="desc2">Meme based crypto</span>
            </div>
          </div>
        </div>

        {/* <div
          className="section-helper-text"
          style={{ margin: !isMobile ? "30px auto" : "0px auto" }}
        >
          Exclusive for Elite Members
        </div> */}

        {/* <div className={`${isMobile ? "d-block" : "d-flex"} mx-2 mt-4`}>
          <div className="abc">
            <Link to={baseDEXURL}>
              <img src={swap} height="60px" />
              <span
                className="desc"
                style={{
                  color: "white",
                }}
              >
                {" "}
                Swap
              </span>
              <span className="desc2" >
                A Decentralized crypto swap application to do anonymous
                transactions
              </span>
            </Link>
          </div>

          <div className="abc">
            <Link to="/indexx-exchange/coming-soon">
              <img src={sportbets} height="60px" />

              <span className="desc" >
                {" "}
                Sport Bets
              </span>
            </Link>
          </div>
          <div className="abc">
            <Link to="/indexx-exchange/coming-soon">
              <img src={sbex} height="60px" />

              <span className="desc" >
                {" "}
                SBEX
              </span>
            </Link>
          </div>
        </div> */}
        {/* <br />
        <br />
        <br /> */}
        {/* <div className="d-flex  mx-2 mt-4">
 
          <div className="abc">
            <a to="https://indexx.ai/indexx-exchange/coming-soon">
              <img src={sorekt} height="60px" />

              <span className="desc" >
                {" "}
                SoRekt
              </span>
            </a>
          </div>
          <div className="abc"></div>
          <div className="abc"></div>
          <br />
          <br />
          <br />
          <br />
        </div> */}
      </div>
    </div>
  );
};

export default Section1;

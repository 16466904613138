import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import exchn from "../../assets/how-it-works/exchange icon.svg";
import exchn_dark from "../../assets/how-it-works/exchange logo.svg";

import swap from "../../assets/how-it-works/new swap logo Black.png";
import swap_dark from "../../assets/how-it-works/new swap logo White-.png";

import tradetoearn from "../../assets/how-it-works/indexx trade to earn.png";

import tokens from "../../assets/how-it-works/iToken LM.svg";
import tokens_dark from "../../assets/how-it-works/iToken DM.svg";

import indexxfortune from "../../assets/how-it-works/fortune LM.svg";
import indexxfortune_dark from "../../assets/how-it-works/fortune DM.svg";

import hive from "../../assets/how-it-works/hive.png";

import xmarket from "../../assets/how-it-works/x market black 1.svg";
import xmarket_white from "../../assets/how-it-works/x market white 1.svg";
import xwallet from "../../assets/how-it-works/wallet ex black 2.svg";
import xwallet_white from "../../assets/how-it-works/wallet white_new 1.svg";
import xnft from "../../assets/how-it-works/xnft_05-min 2.svg";
import xnft_white from "../../assets/how-it-works/xnft white 1.svg";

// import finalsquare from "../../assets/how-it-works/finalsquare.png";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import { transform } from "typescript";
import "./HowItWorks.css";
const { Text } = Typography;

const HowItWorks = () => {
  const navigate = useNavigate();

  const navigateUser = (path: any) => {
    window.localStorage.setItem("redirect", window.location.pathname);
    navigate(path);
  };

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  console.log(theme);

  const isMobile = width <= 768;

  return (
    <>
      <div className="scan-container how-it-works flex-direction-column">
        <Text
          className="card__title"
          style={{
            marginBottom: `${isMobile ? "0px" : "30px"}`,
            fontSize: `${isMobile ? "45px" : "70px"}`,
            lineHeight: "1em",
            marginTop: `${isMobile ? "0" : "50px"}`,
          }}
        >
          How it Works
        </Text>

        <div className="row">
          <div className="col-lg-4 text-center">
            <Link to={"/indexx-exchange/how-it-works/market"}>
              {theme === "dark" ? (
                <img
                  src={xmarket_white}
                  className="logo_prod"
                  alt="indexxcoin"
                  width={"150px"}
                  height={"65px"}
                  // width={"90px"} height={"100px"}
                  style={{ marginTop: 70 }}
                />
              ) : (
                <img
                  src={xmarket}
                  className="logo_prod"
                  alt="indexxcoin"
                  width={"150px"}
                  height={"65px"}
                  // width={"90px"} height={"100px"}
                  style={{ marginTop: 70 }}
                />
              )}
            </Link>
            <br />
            <span className="employee_name">
              <strong> XNFT Marketplace</strong>
            </span>
            <Text style={{ opacity: "50%", fontWeight: "400" }}></Text>
          </div>
          <div className="col-lg-4 text-center">
            <Link
              to={"https://wallet.indexx.ai/Indexx-wallet/wallet-whitepaper"}
            >
              {theme === "dark" ? (
                <img
                  src={xwallet_white}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"150px"}
                  height={"65px"}
                  // width={"90px"} height={"100px"}
                  style={{ marginTop: 70 }}
                />
              ) : (
                <img
                  src={xwallet}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"150px"}
                  height={"65px"}
                  // width={"90px"} height={"100px"}
                  style={{ marginTop: 70 }}
                />
              )}
            </Link>
            <br />
            <span className="employee_name">
              <strong> Wallet</strong>
            </span>
            <Text style={{ opacity: "50%", fontWeight: "400" }}></Text>
          </div>
          <div className="col-lg-4 text-center">
            <Link to={"https://xnft.indexx.ai/"}>
              {theme === "dark" ? (
                <img
                  src={xnft_white}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"140px"}
                  height={"65px"}
                  // width={"90px"} height={"100px"}
                  style={{ marginTop: 70 }}
                />
              ) : (
                <img
                  src={xnft}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"140px"}
                  height={"65px"}
                  // width={"90px"} height={"100px"}
                  style={{ marginTop: 70 }}
                />
              )}
            </Link>
            <br />
            <span className="employee_name">
              <strong>
                {" "}
                XNFT
                <br />
              </strong>
            </span>
            <Text style={{ opacity: "50%", fontWeight: "400" }}></Text>
          </div>
          <div className="col-lg-4 text-center">
            <Link to="/indexx-exchange/how-it-works/centralized">
              {theme === "dark" ? (
                <img
                  src={exchn_dark}
                  alt="indexxcoin"
                  className="logo_prod"
                  // width={"150px"} height={"140px"}
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70 }}
                />
              ) : (
                <img
                  src={exchn}
                  alt="indexxcoin"
                  className="logo_prod"
                  // width={"150px"} height={"140px"}
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70 }}
                />
              )}
            </Link>
            <br />
            <span className="employee_name">
              <strong
                onClick={() =>
                  navigateUser("/indexx-exchange/how-it-works/centralized")
                }
              >
                Exchange
                <br />
              </strong>
            </span>
          </div>
          <div className="col-lg-4 text-center">
            <Link to="/indexx-exchange/how-it-works/decentralized">
              {theme === "dark" ? (
                <img
                  src={swap_dark}
                  alt="swap dark mode icon"
                  className="logo_prod"
                  // width={"150px"} height={"140px"}
                  // width={"90px"} height={"80px"}
                  style={{ marginTop: 70 }}
                />
              ) : (
                <img
                  src={swap}
                  alt="swap light mode icon"
                  className="logo_prod"
                  // width={"150px"} height={"140px"}
                  // width={"90px"} height={"80px"}
                  style={{ marginTop: 70 }}
                />
              )}
              <br />
            </Link>
            <span className="employee_name">
              <strong
                onClick={() =>
                  navigateUser("/indexx-exchange/how-it-works/decentralized")
                }
              >
                Swap
                <br />
              </strong>
            </span>
          </div>
          <div className="col-lg-4 text-center">
            <Link to="/indexx-exchange/how-it-works/tradetoearn">
              <img
                src={tradetoearn}
                alt=""
                className="logo_prod"
                // width={"150px"} height={"140px"}
                width={"90px"}
                height={"80px"}
                style={{ marginTop: 70 }}
              />
              <br />
            </Link>

            <span
              className="employee_name"
              onClick={() =>
                navigateUser("/indexx-exchange/how-it-works/tradetoearn")
              }
            >
              <strong>indexx Trade To Earn</strong>
            </span>
          </div>
          <div className="col-lg-4 text-center">
            <Link to={"/indexx-exchange/how-it-works/tokens"}>
              {theme === "dark" ? (
                <img
                  src={tokens_dark}
                  alt="indexxcoin"
                  className="logo_prod"
                  // width={"150px"} height={"140px"}
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70, marginBottom: 12 }}
                />
              ) : (
                <img
                  src={tokens}
                  alt="indexxcoin"
                  className="logo_prod"
                  // width={"150px"} height={"140px"}
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70, marginBottom: 12 }}
                />
              )}
            </Link>

            <br />
            <span
              className="employee_name"
              onClick={() =>
                navigateUser("/indexx-exchange/how-it-works/fortune")
              }
            >
              <strong>indexx Tokens</strong>
            </span>
          </div>
          <div className="col-lg-4 text-center">
            <Link to={"/indexx-exchange/how-it-works/fortune"}>
              {theme === "dark" ? (
                <img
                  src={indexxfortune_dark}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70, marginBottom: 12 }}
                />
              ) : (
                <img
                  src={indexxfortune}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70, marginBottom: 12 }}
                />
              )}
            </Link>
            <br />
            <span className="employee_name">
              <strong>
                {" "}
                indexx Fortune
                <br />
              </strong>
            </span>
            <Text style={{ opacity: "50%", fontWeight: "400" }}></Text>
          </div>

          <div className="col-lg-4 text-center">
            <Link to={"/indexx-exchange/how-it-works/hive"}>
              {theme === "dark" ? (
                <img
                  src={hive}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70, marginBottom: 12 }}
                />
              ) : (
                <img
                  src={hive}
                  alt="indexxcoin"
                  className="logo_prod"
                  width={"90px"}
                  height={"80px"}
                  style={{ marginTop: 70, marginBottom: 12 }}
                />
              )}
            </Link>
            <br />
            <span className="employee_name">
              <strong>
                {" "}
                Hive Exchange
                <br />
              </strong>
            </span>
            <Text style={{ opacity: "50%", fontWeight: "400" }}></Text>
          </div>

          {/* <div className="col-lg-4 text-center">
            <img src={finalsquare} alt="indexxcoin" style={{ marginTop:70 }} />
            <br />
            <span className="employee_name">
              <strong>
                indexx
                <br />
                Bank
                <br />
              </strong>
            </span>
            <Text style={{ opacity: "50%", fontWeight: "400" }}>
              (coming soon)
            </Text>
          </div> */}
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default HowItWorks;

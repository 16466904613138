import React from "react";
import Footer from "../Footer/Footer";
import { Typography, Button, Image } from "antd";
import "./Tokens.css";
import inex from "../../assets/coinAnimations/inex_4_11zon.png";
import first from "./first.svg";
import second from "./second.svg";
import third from "./third.svg";
import fourth from "./fourth.svg";
import fifth from "./fifth.svg";
import fifthdark from "./fifthdark.svg";
import tokenguy from "./banner.png";
import hands from "../../assets/tokenpages/inex/hand.png";
import hand_dark from "../../assets/tokenpages/inex/hand_dark.png";
import uses from "../../assets/tokenpages/inex/uses.png";
import uses_dark from "../../assets/tokenpages/inex/uses_dark.png";
import table from "../../assets/tokenpages/inex/black_mode_table.svg";
import table_dark from "../../assets/tokenpages/inex/white_mode_table.svg";
import { useEffect, useState } from "react";
import {
  baseCEXURL,
  baseDEXURL,
  decodeJWT,
  getUserShortToken,
} from "../../services/api";
import { useMediaQuery } from "@mui/material";
const { Text } = Typography;

const IndexxEX = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [shortToken, setShortToken] = useState(null);
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    async function checkIsAuthenicated() {
      const isAuthenticated = localStorage.getItem("access_token");
      const email = localStorage.getItem("email");
      let sToken;

      if (email) {
        sToken = await getUserShortToken(email);
        setShortToken(sToken.data);
      } else if (isAuthenticated) {
        let decodedValue: any = await decodeJWT(isAuthenticated);
        sToken = await getUserShortToken(decodedValue?.email);
        setShortToken(sToken.data);
      }
    }
    checkIsAuthenicated();
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);
    // window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      // window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  console.log("theme in inex is :", theme);

  return (
    <>
      {isMobile ? (
        <div
          className="scan-container how-it-works flex-direction-column"
          style={{ gap: "20px", marginTop: "100px" }}
        >
          <Image
            src={inex}
            preview={false}
            width={130}
            style={{ marginTop: "20px", marginBottom: "10px" }}
          ></Image>
          <Text
            style={{
              fontSize: 22,
              opacity: "90%",
              marginBottom: 30,
              color: "var(--body_color)",
              textAlign: "center",
            }}
          >
            indexx Exchange
            <br />
            INEX
          </Text>
          <Image
            src={tokenguy}
            preview={false}
            style={{ width: "100%", marginTop: "100px" }}
          ></Image>

          <div className="row center d-flex justify-content-center align-items-center">
            <img
              src={theme === "dark" ? hand_dark : hands}
              style={{
                marginTop: "160px",
                marginBottom: "20px",
                width: "fit-content",
              }}
            />
          </div>

          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=INEX`
                : `${baseCEXURL}/update/home?buyToken=INEX`
            }
            style={{ textAlign: "center" }}
          >
            <Button
              style={{
                textAlign: "center",
                marginTop: "-100px",
                borderRadius: 5,
                marginLeft: -36,
                height: "55px",
              }}
              type="primary"
              className="secondButton"
            >
              {" "}
              Buy Tokens
            </Button>
          </a>

          <Image
            src={theme === "dark" ? uses_dark : uses}
            preview={false}
            style={{ marginTop: "200px", width: "fit-content" }}
          ></Image>

          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=INEX`
                : `${baseCEXURL}/update/home?buyToken=INEX`
            }
            style={{ textAlign: "center" }}
          >
            <Button
              style={{
                textAlign: "center",
                borderRadius: 5,
                width: 268,
                marginTop: 54,
                height: "55px",
              }}
              type="primary"
              className="secondButton"
            >
              {" "}
              Buy Tokens
            </Button>
          </a>
        </div>
      ) : (
        <div
          className="scan-container how-it-works flex-direction-column  "
          style={{ marginTop: "100px" }}
        >
          <Image
            src={inex}
            preview={false}
            width={130}
            style={{ marginTop: "20px", marginBottom: "10px" }}
          ></Image>
          <Text
            style={{
              fontSize: 22,
              opacity: "90%",
              marginBottom: 30,
              color: "var(--body_color)",
            }}
          >
            indexx Exchange / INEX
          </Text>
          <Image
            src={tokenguy}
            preview={false}
            style={{ width: "100%", marginTop: "150px" }}
          ></Image>

          <div className="row center d-flex justify-content-center">
            <Image
              src={first}
              preview={false}
              width={1025}
              style={{ marginTop: "260px", marginBottom: "-40px" }}
            ></Image>
          </div>

          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=INEX`
                : `${baseCEXURL}/update/home?buyToken=INEX`
            }
            style={{ textAlign: "center" }}
          >
            <Button
              style={{
                textAlign: "center",
                marginTop: "-120px",
                borderRadius: 5,
                marginLeft: -36,
              }}
              type="primary"
              className="secondButton"
            >
              {" "}
              Buy Tokens
            </Button>
          </a>

          <Image
            src={theme === "light" ? table_dark : table}
            preview={false}
            width={1041}
            style={{ marginTop: "200px" }}
          ></Image>

          <Image
            src={second}
            preview={false}
            width={399}
            style={{ marginTop: "380px" }}
          ></Image>

          <Image
            src={third}
            preview={false}
            width={794.17}
            style={{ marginTop: "308px" }}
          ></Image>

          <Image
            src={fourth}
            preview={false}
            width={440}
            style={{ marginTop: "287px" }}
          ></Image>

          <a
            href={
              shortToken
                ? `${baseCEXURL}/update/home?signInToken=${shortToken}&buyToken=INEX`
                : `${baseCEXURL}/update/home?buyToken=INEX`
            }
            style={{ textAlign: "center" }}
          >
            <Button
              style={{
                textAlign: "center",
                borderRadius: 5,
                width: 268,
                marginTop: 54,
              }}
              type="primary"
              className="secondButton"
            >
              {" "}
              Buy Tokens
            </Button>
          </a>
        </div>
      )}

      <Footer></Footer>
    </>
  );
};

export default IndexxEX;
